import React, { Component } from 'react'

import { OAuth } from '@betwixt/common/src/utils/auth'
import { SetError } from '@betwixt/common'

import Assistant from '@betwixt/common/dist/modules/assistant/my/Assistant'
import Navs from '../Navs'
import Footer from '../Footer'
import { getOrSetDefault_Aside_isVisible } from '@betwixt/common/src/utils/page'
class DynamicImport extends Component { 
    
    _isMounted = false

    containerREF = React.createRef();

    state = {
        component: null,
        navsAside_isVisible: getOrSetDefault_Aside_isVisible(),
        NavsAsideExtendedComponent: null
    }


    componentDidMount = async () => {
        this._isMounted = true
        
        this.props.load().then( 
            async (component) => {

                document.body.classList.add(this.props.moduleId)

                const OAuthComp = await OAuth.init(this.props.client, this.props.moduleId)
            
                if(!OAuthComp.ok){
                    
                    if( OAuthComp.errors ){
                        SetError( 
                            "inPAGE",
                            OAuthComp.errors[0].path, 
                            OAuthComp.errors[0].message,
                            this.props.client,
                            this.props.history
                        )
                    } else {
                        OAuth.logout( this.props.client, this.props.history )
                    }

                } else {

                    // navsAsideExtended
                    try {
                        this.setState({
                            ...this.state,
                            NavsAsideExtendedComponent: require(`@betwixt/common/${this.props.moduleUrl}/NavsAsideExtends.js`).default
                        })
                    } catch (err) {}

                    // i18n | addResourceBundle
                    try{
                        
                        if(this.props.moduleConfig && this.props.moduleConfig.module && this.props.moduleConfig.module.languages){
                            this.props.moduleConfig.module.languages.map( (language) => {
                                if( this.props.i18n.getResourceBundle(language, this.props.moduleConfig.module.id) === undefined ){
                                    const resource = require(`@betwixt/common/${this.props.moduleUrl}/translation/${language}.json`)
                                    this.props.i18n.addResourceBundle(language, this.props.moduleConfig.module.id, resource, true, true)
                                }
                                return false;
                            })
                        }
                        
                    } catch (err) {
                        console.error("[MODULE] TRANSLATION DIRECTORY NOT FOUND")
                        console.log(err)
                    }

                    // load css file
                    try{
                        require(`@betwixt/common/${this.props.moduleUrl}/client/scss/index.scss`)
                    } catch (err) {
                        console.error("[MODULE] FILE STYLE NOT FOUND")
                        console.log(err)
                    }

                    // get Module Settings
                    try {
                        const { GET_SETTINGS } = require(`@betwixt/common/${this.props.moduleUrl}/client/graphql/settings`)
                        await this.props.client.query({ query: GET_SETTINGS })
                    } catch (err) { }

                    if (this._isMounted)
                        this.setState(() => ({ component: component.default ? component.default : component }))
                }
                
            }
        )
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove(this.props.moduleId)
    }

    updatedNavsAside(navsAside_isVisible) {
        this.setState({
            ...this.state,
            navsAside_isVisible
        })
    }

    render() {
        return (
            <div className="stage">
                <main role="main" className="flex-row" ref={this.containerREF}>
                    <Navs navType="ASIDE" 
                        pagename={this.props.pagename} 
                        parentInfo={this.containerREF.current}
                        moduleConfig={this.props.moduleConfig}
                        updatedNavsAside={(status) => this.updatedNavsAside(status)}
                        NavsAsideExtendedComponent={this.state.NavsAsideExtendedComponent}
                        />
                    <section id="module" className={`${(this.state.navsAside_isVisible) ? 'navsAside_isVisible' : ''}`}>
                        <Assistant {...this.props} />
                        {this.props.children(this.state.component)}
                    </section>
                </main>
                <Footer i18n={this.props.i18n} without_privacylink={true} />
            </div>
        )
    }
}
export default DynamicImport